import screenshot from "./assets/images/App_Screenshots_1.png";
import logo from "./assets/logos/logo_02_white_3000.png";
// import { useNavigate } from "react-router-dom";
import "./App.css";

function App() {
  // let navigate = useNavigate();
  return (
    <div className="appBackground">
      <img src={logo} className="appLogo" alt="logo" />
      <text className="headerText">AI Powered Image Production.</text>
      <button className="button">
        <text className="buttonText">Get App</text>
      </button>
      <img src={screenshot} className="screenshot" alt="screenshot" />
    </div>
  );
}

export default App;
